import { GET_BRANCHES, SET_ORDERS_ACTIVE_PAGE } from "./constants";

const initialState = {
  count: 0,
  pagination: {},
  branches: [],
  ordersActivePage: 1,
};

export default function branch(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BRANCHES:
      return {
        ...state,
        count: payload.count,
        pagination: payload.pagination,
        branches: payload.data,
      };

    case SET_ORDERS_ACTIVE_PAGE:
      return {
        ...state,
        ordersActivePage: payload,
      };

    default:
      return state;
  }
}
