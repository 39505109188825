import React, { useEffect, useState } from "react";
import {
  CRow,
  CCol,
  CButton,
  CDropdownDivider,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CImg,
  CContainer,
} from "@coreui/react";

import { Timeline, TimelineEvent } from "react-event-timeline";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";

import moment from "moment";
import api from "src/utils/api";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "src/modules/Alert/actions";
import Divider from "../settlement/components/Divider";
import AddonDisplay from "../orders/AddonDisplay";
import { useParams } from "react-router-dom";
import Spinner from "src/components/layout/Spinner";

const OrderDetails = ({ getOrders }) => {
  const { id } = useParams();

  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(true);

  const getOrder = async () => {
    try {
      const res = await api.get(`order/${id}?from=branch`);
      if (res.data.success) {
        setItem(res.data.data);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getOrder();
  }, [id]);

  return (
    <CContainer className={"p-5 pr-10 pl-10"}>
      {loading ? (
        <Spinner />
      ) : (
        <CRow
          style={{
            backgroundColor: "white",
            padding: "30px",
            borderRadius: "5px",
            boxShadow: "0px 1px 9px 10px #e3e3e3",
          }}
        >
          <CCol md="8">
            <CRow>
              <CCol md="6" style={{ float: "left", fontWeight: "bold" }}>
                <h4 style={{ fontWeight: "bold", color: "#016313" }}>
                  {item.vendor?.name}
                </h4>
              </CCol>
              <CCol md="6" style={{ float: "right" }}>
                <CRow>
                  <h6 style={{ fontWeight: "bold" }}>
                    Ordered On: {moment(item.createdAt).format("DD MMM YYYY")}
                  </h6>
                </CRow>
              </CCol>
            </CRow>
            <CDropdownDivider />
            <CRow>
              <CCol style={{ paddingTop: "10px" }}>
                <h6
                  style={{
                    fontWeight: "bold",
                    paddingTop: "10px",
                    color: "darkgreen",
                  }}
                >
                  Customer Details
                </h6>
                <CRow>
                  <CCol>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6 style={{ fontWeight: "bold" }}>Name:</h6>

                      <h6> {item.customer?.name}</h6>
                    </div>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6 style={{ fontWeight: "bold" }}>Contact:</h6>

                      <h6>{item.contactNumber}</h6>
                    </div>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6 style={{ fontWeight: "bold" }}>Secondary Contact:</h6>

                      <h6>{item?.secondaryNumber}</h6>
                    </div>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6 style={{ fontWeight: "bold" }}>Location:</h6>

                      <h6>
                        {item.address.address} , {item.address.landmark} ,{" "}
                        {item.address.formattedAddress}
                      </h6>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
              <CCol style={{ paddingTop: "10px" }}>
                <h6
                  style={{
                    fontWeight: "bold",
                    paddingTop: "10px",
                    color: "darkgreen",
                  }}
                >
                  Vendor Details
                </h6>
                <CRow>
                  <CCol>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6 style={{ fontWeight: "bold" }}>Name:</h6>

                      <h6>{item.vendor.name}</h6>
                    </div>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6 style={{ fontWeight: "bold" }}>Contact:</h6>

                      <h6>{item.vendor?.contactNumber}</h6>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow className="mt-3 mb-3">
              <CCol md="4">
                <h6 style={{ fontWeight: "bold", color: "darkgreen" }}>
                  Delivery Boy:{" "}
                  <span style={{ fontWeight: "500", color: "black" }}>
                    {item.deliveryBoy?.name}
                  </span>
                </h6>
              </CCol>
            </CRow>
            <CDropdownDivider />

            {item.items.map((payload, index) => {
              return (
                <CRow key={payload._id + payload.name + payload.packingCharge}>
                  <CCol md="3">
                    <h6
                      style={{
                        border: "1px solid",
                        borderColor: "lightgrey",
                        justifyContent: "center",
                        alignSelf: "center",
                        display: "flex",
                        borderRadius: "5px",
                        padding: "3px",
                      }}
                    >
                      X {payload.qty}
                    </h6>
                  </CCol>

                  <CCol md="5">
                    <h6
                      style={{
                        fontWeight: "bold",
                        paddingTop: "3px",
                        display: "flex",
                      }}
                    >
                      {payload.name}
                    </h6>
                    {payload.addons && payload.addons.length > 0 ? (
                      <AddonDisplay addons={payload.addons} />
                    ) : null}
                  </CCol>
                  <CCol md="3">
                    <h6
                      style={{
                        border: "1px solid",
                        borderColor: "lightgrey",
                        justifyContent: "center",
                        alignSelf: "center",
                        display: "flex",
                        borderRadius: "5px",
                        padding: "3px",
                      }}
                    >
                      ₹{parseFloat(payload.packingCharge)}
                    </h6>
                  </CCol>
                </CRow>
              );
            })}
            <CDropdownDivider />
            <CRow className="mt-5">
              <CCol md="7">
                <h6>
                  Vendor Payable: ₹{" "}
                  {Math.round(item.vendorCommissionTotal).toFixed(2)}
                </h6>
                <h6>
                  Commission: ₹{" "}
                  {Math.round(
                    item.vendorTotalAmount - item.vendorCommissionTotal
                  ).toFixed(2)}
                </h6>
              </CCol>
              <CCol md="5">
                <h6>Delivery Charge: ₹ {item.deliveryCharge}</h6>
                <h6>Delivery Tip: ₹ {item.deliveryTip}</h6>
                <h6>GST: ₹ {item.gst}</h6>
                <h6>Packing Charge: ₹ {item.extraCharge}</h6>
                <h6>Sub Total: ₹ {item.subTotalAmount}</h6>
                <h6>Discount: ₹ {item.discount}</h6>
                <h6 style={{ fontWeight: "bold" }}>
                  Total: ₹ {item.totalAmount}
                </h6>
              </CCol>
            </CRow>
            <CRow className="mt-3">
              <CCol>
                <div
                  style={{
                    border: "1px solid #d1d1d1",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  <h5>Order Note</h5>
                  <Divider width={50} />
                  {item.note}
                  {item.orderNote.map((note) => {
                    return (
                      <div className="mb-3">
                        <h6 className="mb-0">{note.note}</h6>
                        <small>{note.author}</small>
                      </div>
                    );
                  })}
                </div>
              </CCol>
            </CRow>
            {item?.deliveryPending && (
              <CRow>
                <CCol
                  style={{
                    borderColor: "red",
                    color: "black",
                    backgroundColor: "lightgrey",
                    border: "1px solid",
                    display: "flex",

                    borderRadius: "4px",

                    padding: "5px",
                  }}
                >
                  <h6>{item.deliveryPending.type}</h6>
                  <p>{item.deliveryPending.reason}</p>
                </CCol>
              </CRow>
            )}
          </CCol>
          <CCol
            md="4"
            style={{
              height: "500px",
              position: "relative",
              overflowY: "auto",
            }}
          >
            <Timeline>
              {item.status.map((s) => {
                return (
                  <TimelineEvent
                    className="mt-4"
                    key={s.updated}
                    title={s.info}
                    createdAt={moment(s.updated).format("DD MMM YYYY hh:mm A")}
                    icon={<i className="material-icons md-18"></i>}
                  />
                );
              })}
            </Timeline>
          </CCol>
        </CRow>
      )}
    </CContainer>
  );
};

export default OrderDetails;
