import {
  GET_BANNERS,
  GET_STORES,
  GET_GROCERY_STORES,
  LOADING,
  LOADED,
  GET_BRANCHORDER,
  GET_DELIVERYBOY,
  GET_EXECUTIVES,
  SET_CURRENT_RESTAURANT,
  GET_PRODUCTS,
  SET_PAGINATION,
  SET_DBANALYSIS,
  GET_PROMOCODES,
  GET_MEAT_STORES,
  SET_CURRENT_GROCERY,
  GET_GROCERY_PRODUCTS,
  SET_GROCERY_ACTIVE_PAGE,
  SET_GROCERY_KEYWORD,
  SET_GROCERY_PAGES,
  SET_CURRENT_MEAT,
  GET_MEAT_PRODUCTS,
  GET_ALL_STORES,
  GET_NOTIFICATION,
} from "./constants";

const initialState = {
  stores: [],
  groceryStores: [],
  meatStores: [],
  loading: true,
  branchBanners: [],
  orders: [],
  deliveryBoys: [],
  executives: [],
  currentStore: "",
  products: [],
  pagination: {},
  DBAnalysis: {},
  promocodes: [],
  currentGroceryStore: "",
  groceryProductsCount: 0,
  groceryProducts: [],
  groceryOnePageLimit: 10,
  groceryAcitvePage: 2,
  groceryKeyword: "",
  groceryPages: 0,
  currentMeatStore: "",
  meatProducts: [],
  meatProductsCount: 0,
  allStores: [],
  notifications: [],
};

export default function store(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOADED:
      return {
        ...state,
        loading: false,
      };

    case GET_STORES:
      return {
        ...state,
        loading: false,
        stores: payload.data,
        pagination: { ...state.pagination, total: payload.count },
      };

    case GET_GROCERY_STORES:
      return {
        ...state,
        loading: false,
        groceryStores: payload.data,
        pagination: { ...state.pagination, total: payload.count },
      };

    case GET_MEAT_STORES:
      return {
        ...state,
        loading: false,
        meatStores: payload.data,
        pagination: { ...state.pagination, total: payload.count },
      };

    case GET_BRANCHORDER:
      return {
        ...state,
        pagination: { ...state.pagination, total: payload.count },
        orders: payload.data,
      };
    case GET_DELIVERYBOY:
      return {
        ...state,
        deliveryBoys: payload.data,
        pagination: { ...state.pagination, total: payload.count },
      };
    case SET_CURRENT_RESTAURANT:
      return {
        ...state,
        currentStore: payload,
      };
    case SET_CURRENT_GROCERY:
      return {
        ...state,
        currentGroceryStore: payload,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: payload.data,
        loading: false,
        pagination: { ...state.pagination, total: payload.total },
      };
    case SET_PAGINATION:
      return {
        ...state,
        pagination: { ...state.pagination, page: payload },
      };
    case GET_EXECUTIVES:
      return {
        ...state,
        pagination: { ...state.pagination, total: payload.count },
        executives: payload.data,
      };
    case SET_DBANALYSIS:
      return {
        ...state,
        DBAnalysis: payload,
      };
    case GET_PROMOCODES:
      return {
        ...state,
        promocodes: payload,
      };

    case GET_GROCERY_PRODUCTS:
      return {
        ...state,
        groceryProducts: payload.data,
        groceryKeyword: payload.keyword,
        groceryPages: payload.pages,
        groceryAcitvePage: payload.activePage,
        groceryProductsCount: payload.totalProductsCount,
      };

    case SET_GROCERY_ACTIVE_PAGE:
      return {
        ...state,
        groceryAcitvePage: payload,
      };

    case SET_GROCERY_KEYWORD:
      return {
        ...state,
        groceryKeyword: payload,
      };

    case SET_GROCERY_PAGES:
      return {
        ...state,
        groceryPages: payload,
      };

    case SET_CURRENT_MEAT:
      return {
        ...state,
        currentMeatStore: payload,
      };

    case GET_MEAT_PRODUCTS:
      return {
        ...state,
        meatProducts: payload.data,
        meatProductsCount: payload.productsCount,
        loading: false,
      };

    case GET_ALL_STORES:
      return {
        ...state,
        allStores: payload.data,
        loading: false,
      };

    case GET_NOTIFICATION:
      return {
        ...state,
        notifications: payload.data,
        loading: false,
      };

    default:
      return state;
  }
}
