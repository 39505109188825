import api from "../../utils/api";
import { setAlert } from "../Alert/actions";
import {
  APP_STATUS,
  LOADED,
  LOADING,
  GET_CATEGORY,
  GET_CATEGORY_TYPES,
  GET_GROCERY_CATEGORY,
  GET_MEAT_CATEGORY,
} from "./constants";

// Add category
export const addCategory = (data) => async (dispatch) => {
  const fData = {
    name: data.name,
    status: false,
    timing: data.timing,
  };
  try {
    const res = await api.post("/category", fData);
    dispatch(getCategory());
    dispatch(setAlert("Category Added", "success"));
  } catch (error) {
    console.log(error);
  }
};

// Edit Restaurant Category
export const editCategory = (data) => async (dispatch) => {
  try {
    const res = await api.put("/category", data);
    if (res.data.success) {
      dispatch(setAlert(res.data.message, "success"));
      dispatch(getCategory());
    }
  } catch (err) {
    console.log(err.response);
    dispatch(setAlert("Error occured", "danger"));
  }
};

// Load Category
export const getCategory = () => async (dispatch) => {
  try {
    const res = await api.get("/category");

    dispatch({ type: GET_CATEGORY, payload: res.data.data });
  } catch (err) {
    console.log(err);
  }
};

// Delete Category
export const deleteCategory = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/category/${id}`);
    dispatch(getCategory());
    dispatch(setAlert("Category Deleted", "danger"));
  } catch (err) {
    console.log(err);
  }
};

// // change app status
export const updateSettings = (data) => async (dispatch) => {
  try {
    const res = await api.put("/settings", data);
    dispatch(getSettings());
    dispatch(setAlert("Store Settings changed", "success"));
  } catch (err) {
    console.log(err);
  }
};

// // get app status
export const getSettings = () => async (dispatch) => {
  try {
    const res = await api.put("/settings");

    dispatch({ type: APP_STATUS, payload: res.data.data });
  } catch (err) {
    console.log(err);
  }
};

//get category types
export const getCategoryTypes = () => async (dispatch) => {
  try {
    const res = await api.get(`/category/category-type`);
    if (res.data.success) {
      dispatch({
        type: GET_CATEGORY_TYPES,
        payload: res.data,
      });
    } else {
      console.log(res);
    }
  } catch (err) {
    console.log(err.response);
  }
};

//add category type
export const addCategoryType =
  (name, image, bannerImage, description, rank) => async (dispatch) => {
    try {
      let fd = new FormData();
      fd.append("name", name);
      fd.append("description", description);
      fd.append("rank", rank);
      fd.append("filename", "categoryType");
      fd.append("folder", "categorytype");
      fd.append("image", image);
      fd.append("image", bannerImage);
      const res = await api.post("/category/category-type", fd);
      if (res.data.success) {
        dispatch(setAlert(res.data.message, "success"));
        dispatch(getCategoryTypes());
      } else {
        console.log(res);
      }
    } catch (err) {
      dispatch(setAlert("Error occured", "danger"));
      console.log(err.response);
    }
  };

//delete Category type
export const deleteCategoryType = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/category/category-type?id=${id}`);
    if (res.data.success) {
      dispatch(setAlert(res.data.message, "danger"));
      dispatch(getCategoryTypes());
    } else {
      console.log(res);
    }
  } catch (err) {
    dispatch(setAlert("Error occured", "danger"));
    console.log(err.response);
  }
};

// edit categroy type
export const editCategoryType = (data) => async (dispatch) => {
  try {
    let fd = new FormData();
    fd.append("name", data.name);
    fd.append("rank", data.rank ? data.rank : 1);
    fd.append("description", data.description);
    fd.append(
      "imageFile",
      data.image && data.bannerImage
        ? "all"
        : data.image
        ? "image"
        : data.bannerImage
        ? "banner"
        : null
    );
    fd.append("filename", "categorytype");
    fd.append("folder", "categorytype");
    if (data.image) {
      fd.append("image", data.image);
    }
    if (data.bannerImage) {
      fd.append("image", data.bannerImage);
    }
    const res = await api.put(`/category/category-type?id=${data.id}`, fd);
    if (res.data.success) {
      dispatch(setAlert(res.data.message, "success"));
      dispatch(getCategoryTypes());
    } else {
      dispatch(setAlert("Error occured", "danger"));
      console.log(res);
    }
  } catch (err) {
    dispatch(setAlert("Error occured", "danger"));
    console.log(err.response);
  }
};

// get all grocery categories
export const getGroceryCategories = () => async (dispatch) => {
  try {
    const res = await api.get(`/category/grocery-category`);
    if (res.data.success) {
      dispatch({
        type: GET_GROCERY_CATEGORY,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err.response);
  }
};

//add grocery category to branch
export const addBranchGroceryCategory =
  (data) => async (dispatch, getState) => {
    try {
      let fd = new FormData();
      fd.append("name", data.name);
      fd.append("folder", "category");
      fd.append("filename", "category");
      fd.append("image", data.image);
      const res = await api.post(`/category/grocery-category`, fd);
      if (res.data.success) {
        dispatch(setAlert("Category Added", "success"));
        dispatch(getGroceryCategories());
      }
    } catch (err) {
      console.log(err);
      dispatch(setAlert("Error Occurred"));
    }
  };

//edit grocery category in branch
export const editBranchGroceryCategory =
  (data) => async (dispatch, getState) => {
    try {
      let fd = new FormData();
      fd.append("name", data.name);
      fd.append("folder", "category");
      fd.append("filename", "category");
      if (data.image) {
        fd.append("image", data.image);
      }
      const res = await api.put(
        `/category/grocery-category?categoryId=${data.categoryId}`,
        fd
      );
      if (res.data.success) {
        dispatch(setAlert("Category Updated", "success"));
        dispatch(getGroceryCategories());
      }
    } catch (err) {
      console.log(err);
      dispatch(setAlert("Error Occurred"));
    }
  };

//delete grocery category in branch
export const deleteBranchGroceryCategory =
  (id) => async (dispatch, getState) => {
    try {
      const res = await api.delete(
        `/category/grocery-category?categoryId=${id}`
      );
      if (res.data.success) {
        dispatch(setAlert("Category Deleted", "danger"));
        dispatch(getGroceryCategories());
      }
    } catch (err) {
      console.log(err);
      dispatch(setAlert("Error Occurred"));
    }
  };

//add Meat category to branch
export const addBranchMeatCategory = (data) => async (dispatch, getState) => {
  try {
    let fd = new FormData();
    fd.append("name", data.name);
    fd.append("folder", "category");
    fd.append("filename", "category");
    fd.append("image", data.image);
    const res = await api.post(`/category/meat-category`, fd);
    if (res.data.success) {
      dispatch(setAlert("Category Added", "success"));
      dispatch(getMeatCategories());
    }
  } catch (err) {
    console.log(err);
    dispatch(setAlert("Error Occurred"));
  }
};

// get all meat categories
export const getMeatCategories = () => async (dispatch) => {
  try {
    const res = await api.get(`/category/meat-category`);
    if (res.data.success) {
      dispatch({
        type: GET_MEAT_CATEGORY,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err.response);
  }
};

//edit meat category in branch
export const editBranchMeatCategory = (data) => async (dispatch, getState) => {
  try {
    let fd = new FormData();
    fd.append("name", data.name);
    fd.append("folder", "category");
    fd.append("filename", "category");
    if (data.image) {
      fd.append("image", data.image);
    }
    const res = await api.put(
      `/category/meat-category?categoryId=${data.categoryId}`,
      fd
    );
    if (res.data.success) {
      dispatch(setAlert("Category Updated", "success"));
      dispatch(getMeatCategories());
    }
  } catch (err) {
    console.log(err);
    dispatch(setAlert("Error Occurred"));
  }
};

//delete meat category in branch
export const deleteBranchMeatCategory = (id) => async (dispatch, getState) => {
  try {
    const res = await api.delete(`/category/meat-category?categoryId=${id}`);
    if (res.data.success) {
      dispatch(setAlert("Category Deleted", "danger"));
      dispatch(getMeatCategories());
    }
  } catch (err) {
    console.log(err);
    dispatch(setAlert("Error Occurred"));
  }
};

//Update category status
export const updateCategoryStatus = (id, status) => async (dispatch) => {
  try {
    const res = await api.put(
      `/category/update?categoryId=${id}&status=${status}`
    );
    if (res.data.success) {
      dispatch(setAlert(res.data.message, "success"));
      return true;
    }
  } catch (err) {
    console.log(err.response);
    dispatch(setAlert("Error occured", "danger"));
  }
};

// Add Restaurant category
export const addRestCategory = (data) => async (dispatch) => {
  const fData = {
    name: data.name,
    position: data?.position,
  };
  try {
    const res = await api.post("/category", fData);
    dispatch(getRestCategory());
    dispatch(setAlert("Category Added", "success"));
  } catch (error) {
    console.log(error);
  }
};

// Load Restaurant Category
export const getRestCategory = () => async (dispatch) => {
  try {
    const res = await api.get("/category");

    dispatch({ type: GET_CATEGORY, payload: res.data.data });
  } catch (err) {
    console.log(err);
  }
};

// Edit Restaurant Category
export const editRestCategory = (id, name, position) => async (dispatch) => {
  let data = {
    id,
    name,
    position,
  };
  try {
    const res = await api.put("/category", data);
    if (res.data.success) {
      dispatch(setAlert(res.data.message, "success"));
      dispatch(getRestCategory());
    }
  } catch (err) {
    console.log(err.response);
    dispatch(setAlert("Error occured", "danger"));
  }
};

// Delete category
export const deleteCat = (catId) => async (dispatch) => {
  try {
    const res = await api.delete("/category", { params: { catId } });
    if (res.data.success) {
      dispatch(setAlert(res.data.message, "success"));
      dispatch(getRestCategory());
    }
  } catch (err) {
    console.log(err.response);
    dispatch(setAlert("Error occured", "danger"));
  }
};
