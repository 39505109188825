import React from "react";

const AddonDisplay = ({ addons }) => {
  return (
    <>
      <h6
        style={{
          fontSize: "12px",
          display: "flex",
          color: "grey",
        }}
      >
        {addons.map(
          (item, index) =>
            item.name +
            ` ₹${item.offerPrice ? item.offerPrice : item.maafosPrice} ,`
        )}
      </h6>
    </>
  );
};

export default AddonDisplay;
