import { Redirect } from "react-router-dom";
import api from "../../utils/api";
import { setAlert } from "../Alert/actions";
import { getExecutives } from "../Branch/actions";
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  GET_EXECUTIVE,
} from "./constants";
import { GET_BRANCH } from "./constants";

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get("/auth/me");
    if (res.data.data.role === "branch") {
      dispatch(getMyBranch());
    } else if (
      ["deliveryexecutive", "offlineexecutive", "orderexecutive"].includes(
        res.data.data.role
      )
    ) {
      dispatch(getExecutive());
    }
    dispatch({
      type: USER_LOADED,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getExecutive = () => async (dispatch) => {
  try {
    const res = await api.get("/executive/me");
    dispatch({
      type: GET_EXECUTIVE,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err);
  }
};

// change status
export const changeStatus = (status, data) => async (dispatch, getState) => {
  const role = getState().auth.user.role;

  try {
    const res = await api.put(`/executive/status?status=${status}`, data);
    dispatch(setAlert("Status updated", "success"));

    {
      role === "branch"
        ? dispatch(getExecutives())
        : dispatch({
            type: GET_EXECUTIVE,
            payload: res.data.data,
          });
    }
  } catch (err) {
    console.log(err);
  }
};

//get logged in branch details
export const getMyBranch = () => async (dispatch) => {
  try {
    const res = await api.get("/branch/me");

    dispatch({
      type: GET_BRANCH,
      payload: res.data.branch,
    });
  } catch (error) {
    console.log(error);
  }
};

// Login User
export const login = (username, password) => async (dispatch) => {
  const body = { username, password };
  try {
    const res = await api.post("/auth/user-login", body);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.error;
    if (errors) {
      dispatch(setAlert(errors, "danger"));
    }
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Logout
export const logout = () => (dispatch) => {
  <Redirect to="/" />;
  dispatch({
    type: LOGOUT,
  });
};
