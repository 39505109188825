export const GET_STORES = "GET_STORES"; //to get restaurants
export const GET_GROCERY_STORES = "GET_GROCERY_STORES"; // to get grocery stores
export const GET_MEAT_STORES = "GET_MEAT_STORES"; // to get meat stores
export const STORE_ERROR = "STORE_ERROR";
export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const ADD_DELIVERYBOY = "ADD_DELIVERYBOY";
export const GET_DELIVERYBOY = "GET_DELIVERYBOY";
export const GET_BRANCHORDER = "GET_BRANCHORDER";
export const GET_EXECUTIVES = "GET_EXECUTIVES";
export const GET_BANNER = "GET_BANNER";
export const BANNER_ERROR = "BANNER_ERROR";
export const GET_NOTIFICATION = "GET_NOTIFICATION";

export const SET_CURRENT_RESTAURANT = "SET_CURRENT_RESTAURANT";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const SET_PAGINATION = "SET_PAGINATION";
export const SET_DBANALYSIS = "SET_DBANALYSIS";
export const GET_PROMOCODES = "GET_PROMOCODES";

export const SET_CURRENT_GROCERY = "SET_CURRENT_GROCERY";
export const GET_GROCERY_PRODUCTS = "GET_GROCERY_PRODUCTS";
export const SET_GROCERY_ACTIVE_PAGE = "SET_GROCERY_ACTIVE_PAGE";
export const SET_GROCERY_KEYWORD = "SET_GROCERY_KEYWORD";
export const SET_GROCERY_PAGES = "SET_GROCERY_PAGES";

export const SET_CURRENT_MEAT = "SET_CURRENT_MEAT";
export const GET_MEAT_PRODUCTS = "GET_MEAT_PRODUCTS";

export const GET_ALL_STORES = "GET_ALL_STORES"; //to get all vendors
