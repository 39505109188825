import React, { useState } from "react";
import api from "../../utils/api";
import { setAlert } from "../Alert/actions";
import { getGroceryCategories } from "../Settings/actions";
import { getMyBranch, loadUser } from "../User/actions";
import {
  GET_STORES,
  GET_DELIVERYBOY,
  GET_PRODUCTS,
  LOADING,
  LOADED,
  GET_BRANCHORDER,
  GET_EXECUTIVES,
  SET_DBANALYSIS,
  GET_PROMOCODES,
  GET_GROCERY_STORES,
  GET_MEAT_STORES,
  GET_GROCERY_PRODUCTS,
  GET_MEAT_PRODUCTS,
  GET_ALL_STORES,
  GET_NOTIFICATION,
} from "./constants";

// Add Restaurant
export const addStore = (data) => async (dispatch, getState) => {
  const myBranchId = getState().auth.branch._id;
  var success = false;
  let fd = new FormData();

  fd.append("username", data.username);
  fd.append("name", data.name);
  fd.append("branch", myBranchId);
  fd.append("type", "restaurant");
  data.rating && fd.append("rating", data.rating ? data.rating : null);
  fd.append("featured", data.featured);
  fd.append("hasGst", data.hasGst);

  fd.append("location.formattedAddress", data.formattedAddress);
  fd.append("location.address", data.address);
  fd.append("location.coordinates", data.longitude);
  fd.append("location.coordinates", data.latitude);
  fd.append("location.landmark", data.landmark);

  fd.append("password", data.password);
  fd.append("openTime", data.openTime);
  fd.append("closeTime", data.closeTime);
  fd.append("cuisine", data.cuisine);
  fd.append("commission", data.commission);
  fd.append("radius", data.radius);
  fd.append("sortOrder", data.sortOrder);
  data.minimumOrderValue &&
    fd.append("minimumOrderValue", data.minimumOrderValue);
  data.avgCookingTime && fd.append("avgCookingTime", data.avgCookingTime);
  data.avgPersonAmt && fd.append("avgPersonAmt", data.avgPersonAmt);
  data.gst && fd.append("gst", data.gst);
  data.fssai && fd.append("fssai", data.fssai);
  data.quickDelivery && fd.append("quickDelivery", data.quickDelivery);
  data.contactNumber && fd.append("contactNumber", data.contactNumber);
  data.extraCharge && fd.append("extraCharge", data.extraCharge);
  fd.append("filename", "final");
  fd.append("folder", "vendor");
  fd.append("image", data.storeBg);
  fd.append("image", data.storeLogo);
  try {
    const res = await api.post("/vendor", fd);

    if (res.data.success) {
      dispatch(getStores());
      dispatch(setAlert("Restaurant Created Successfully", "success"));
    }
    success = true;
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "danger"));
  }
  return success;
};

// Edit Restaurant
export const editStore = (data) => async (dispatch, getState) => {
  let fd = new FormData();

  fd.append("name", data.name);
  fd.append("type", "restaurant");

  fd.append("location.formattedAddress", data.formattedAddress);
  fd.append("location.address", data.address);
  fd.append("location.coordinates", data.coordinates[0]);
  fd.append("location.coordinates", data.coordinates[1]);
  fd.append("location.landmark", data.landmark);
  data.rating && fd.append("rating", data.rating ? data.rating : null);
  fd.append("featured", data.featured);
  fd.append("hasGst", data.hasGst);
  fd.append("radius", data.radius);

  fd.append("openTime", data.openTime);
  fd.append("closeTime", data.closeTime);
  fd.append("cuisine", data.cuisine);
  fd.append("commission", data.commission);
  fd.append("sortOrder", data.sortOrder);
  fd.append("quickDelivery", data.quickDelivery);
  data.minimumOrderValue &&
    fd.append("minimumOrderValue", data.minimumOrderValue);
  data.password && fd.append("password", data.password);
  data.avgCookingTime && fd.append("avgCookingTime", data.avgCookingTime);
  data.avgPersonAmt && fd.append("avgPersonAmt", data.avgPersonAmt);
  data.gst && fd.append("gst", data.gst);
  data.fssai && fd.append("fssai", data.fssai);
  data.contactNumber && fd.append("contactNumber", data.contactNumber);
  fd.append("extraCharge", data.extraCharge);

  if (data.editLogo && data.editBg) {
    fd.append("edit", "all");
    fd.append("filename", "final");
    fd.append("folder", "vendor");
    fd.append("image", data.storeBg);
    fd.append("image", data.storeLogo);
  } else if (data.editLogo) {
    fd.append("edit", "logo");
    fd.append("filename", "final");
    fd.append("folder", "vendor");
    fd.append("image", data.storeLogo);
  } else if (data.editBg) {
    fd.append("edit", "bg");
    fd.append("filename", "final");
    fd.append("folder", "vendor");
    fd.append("image", data.storeBg);
  }

  try {
    const res = await api.put(`/vendor/${data._id}`, fd);
    if (res.data.success) {
      dispatch(getStores());
      dispatch(setAlert("Restaurant Updated Successfully", "success"));
    }
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

// Add Grocery Store
export const addGroceryStore = (data) => async (dispatch, getState) => {
  const myBranchId = getState().auth.branch._id;

  var success = false;
  let fd = new FormData();
  fd.append("username", data.username);
  fd.append("name", data.name);
  fd.append("branch", myBranchId);
  fd.append("type", "grocery");
  fd.append("rating", data.rating ? data.rating : 0);
  fd.append("featured", data.featured);
  fd.append("hasGst", data.hasGst);

  fd.append("location.formattedAddress", data.formattedAddress);
  fd.append("location.address", data.address);
  fd.append("location.coordinates", data.longitude);
  fd.append("location.coordinates", data.latitude);
  fd.append("location.landmark", data.landmark);

  fd.append("password", data.password);
  fd.append("openTime", data.openTime);
  fd.append("closeTime", data.closeTime);
  fd.append("commission", data.commission);
  fd.append("radius", data.radius);
  fd.append("sortOrder", data.sortOrder);
  data.minimumOrderValue &&
    fd.append("minimumOrderValue", data.minimumOrderValue);
  data.gst && fd.append("gst", data.gst);
  data.fssai && fd.append("fssai", data.fssai);
  data.quickDelivery && fd.append("quickDelivery", data.quickDelivery);
  data.contactNumber && fd.append("contactNumber", data.contactNumber);

  fd.append("filename", "final");
  fd.append("folder", "vendor");
  fd.append("image", data.storeBg);
  fd.append("image", data.storeLogo);
  try {
    const res = await api.post("/vendor", fd);

    if (res.data.success) {
      dispatch(setAlert("Store Created Successfully", "success"));
      dispatch(getGroceryStores());
    }
    success = true;
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "info"));
  }
  return success;
};

// Edit Grocery Store
export const editGroceryStore = (data) => async (dispatch, getState) => {
  let fd = new FormData();

  fd.append("name", data.name);
  fd.append("type", "grocery");

  fd.append("location.formattedAddress", data.formattedAddress);
  fd.append("location.address", data.address);
  fd.append("location.coordinates", data.coordinates[0]);
  fd.append("location.coordinates", data.coordinates[1]);
  fd.append("location.landmark", data.landmark);
  fd.append("rating", data.rating);
  fd.append("featured", data.featured);
  fd.append("hasGst", data.hasGst);

  fd.append("openTime", data.openTime);
  fd.append("closeTime", data.closeTime);
  fd.append("commission", data.commission);
  fd.append("radius", data.radius);
  fd.append("sortOrder", data.sortOrder);
  fd.append("quickDelivery", data.quickDelivery);
  data.minimumOrderValue &&
    fd.append("minimumOrderValue", data.minimumOrderValue);
  data.password && fd.append("password", data.password);
  data.gst && fd.append("gst", data.gst);
  data.fssai && fd.append("fssai", data.fssai);
  data.contactNumber && fd.append("contactNumber", data.contactNumber);

  if (data.editLogo && data.editBg) {
    fd.append("edit", "all");
    fd.append("filename", "final");
    fd.append("folder", "vendor");
    fd.append("image", data.storeBg);
    fd.append("image", data.storeLogo);
  } else if (data.editLogo) {
    fd.append("edit", "logo");
    fd.append("filename", "final");
    fd.append("folder", "vendor");
    fd.append("image", data.storeLogo);
  } else if (data.editBg) {
    fd.append("edit", "bg");
    fd.append("filename", "final");
    fd.append("folder", "vendor");
    fd.append("image", data.storeBg);
  }

  try {
    const res = await api.put(`/vendor/${data._id}`, fd);
    if (res.data.success) {
      dispatch(setAlert("Store Updated Successfully", "success"));
      dispatch(getGroceryStores());
    }
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

// Add Meat Store
export const addMeatStore = (data) => async (dispatch, getState) => {
  const myBranchId = getState().auth.branch._id;
  var success = false;
  let fd = new FormData();
  fd.append("username", data.username);
  fd.append("name", data.name);
  fd.append("branch", myBranchId);
  fd.append("type", "meat");
  fd.append("rating", data.rating ? data.rating : null);
  fd.append("featured", data.featured);
  fd.append("hasGst", data.hasGst);

  fd.append("location.formattedAddress", data.formattedAddress);
  fd.append("location.address", data.address);
  fd.append("location.coordinates", data.longitude);
  fd.append("location.coordinates", data.latitude);
  fd.append("location.landmark", data.landmark);

  fd.append("password", data.password);
  fd.append("openTime", data.openTime);
  fd.append("closeTime", data.closeTime);
  fd.append("commission", data.commission);
  fd.append("radius", data.radius);
  fd.append("sortOrder", data.sortOrder);
  data.minimumOrderValue &&
    fd.append("minimumOrderValue", data.minimumOrderValue);
  data.gst && fd.append("gst", data.gst);
  data.fssai && fd.append("fssai", data.fssai);
  data.quickDelivery && fd.append("quickDelivery", data.quickDelivery);
  data.contactNumber && fd.append("contactNumber", data.contactNumber);
  fd.append("filename", "final");
  fd.append("folder", "vendor");
  fd.append("image", data.storeBg);
  fd.append("image", data.storeLogo);
  try {
    const res = await api.post("/vendor", fd);

    if (res.data.success) {
      dispatch(setAlert("Store Created Successfully", "success"));
      dispatch(getMeatStores());
    }
    success = true;
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "info"));
  }
  return success;
};

// Edit Meat Store
export const editMeatStore = (data) => async (dispatch, getState) => {
  let fd = new FormData();

  fd.append("name", data.name);
  fd.append("type", "meat");

  fd.append("location.formattedAddress", data.formattedAddress);
  fd.append("location.address", data.address);
  fd.append("location.coordinates", data.coordinates[0]);
  fd.append("location.coordinates", data.coordinates[1]);
  fd.append("location.landmark", data.landmark);
  fd.append("rating", data.rating);
  fd.append("featured", data.featured);
  fd.append("hasGst", data.hasGst);

  fd.append("openTime", data.openTime);
  fd.append("closeTime", data.closeTime);
  fd.append("commission", data.commission);
  fd.append("radius", data.radius);
  fd.append("sortOrder", data.sortOrder);
  fd.append("quickDelivery", data.quickDelivery);
  data.minimumOrderValue &&
    fd.append("minimumOrderValue", data.minimumOrderValue);
  data.password && fd.append("password", data.password);
  data.gst && fd.append("gst", data.gst);
  data.fssai && fd.append("fssai", data.fssai);
  data.contactNumber && fd.append("contactNumber", data.contactNumber);

  if (data.editLogo && data.editBg) {
    fd.append("edit", "all");
    fd.append("filename", "final");
    fd.append("folder", "vendor");
    fd.append("image", data.storeBg);
    fd.append("image", data.storeLogo);
  } else if (data.editLogo) {
    fd.append("edit", "logo");
    fd.append("filename", "final");
    fd.append("folder", "vendor");
    fd.append("image", data.storeLogo);
  } else if (data.editBg) {
    fd.append("edit", "bg");
    fd.append("filename", "final");
    fd.append("folder", "vendor");
    fd.append("image", data.storeBg);
  }

  try {
    const res = await api.put(`/vendor/${data._id}`, fd);
    if (res.data.success) {
      dispatch(setAlert("Store Updated Successfully", "success"));
      dispatch(getMeatStores());
    }
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

//get all restaurants
export const getStores = () => async (dispatch, getState) => {
  try {
    const branch =
      getState().auth.user.role === "branch"
        ? getState().auth.branch._id
        : getState().auth.executive?.branch?._id;

    if (branch) {
      const res = await api.get(
        `/vendor?branch=${branch}&type=restaurant&sort=sortOrder`
      );

      dispatch({
        type: GET_STORES,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

//get all Grocery Stores
export const getGroceryStores = () => async (dispatch, getState) => {
  try {
    const branch =
      getState().auth.user.role === "branch"
        ? getState().auth.branch._id
        : getState().auth.executive?.branch?._id;

    if (branch) {
      const res = await api.get(
        `/vendor?branch=${branch}&type=grocery&sort=sortOrder`
      );
      dispatch({
        type: GET_GROCERY_STORES,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

//get all Meat Stores
export const getMeatStores = () => async (dispatch, getState) => {
  try {
    const branch =
      getState().auth.user.role === "branch"
        ? getState().auth.branch._id
        : getState().auth.executive?.branch?._id;

    if (branch) {
      const res = await api.get(
        `/vendor?branch=${branch}&type=meat&sort=sortOrder`
      );
      dispatch({
        type: GET_MEAT_STORES,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

// Delete Restaurants/Grocery/Meat
export const deleteStore = (id, type) => async (dispatch) => {
  try {
    const res = await api.delete(`/vendor/${id}`);
    if (res.data.success) {
      dispatch(setAlert("Restaurant Deleted", "danger"));
      if (type === "restaurant") {
        dispatch(getStores());
      } else if (type === "grocery") {
        dispatch(getGroceryStores());
      } else if (type === "meat") {
        dispatch(getMeatStores());
      } else {
      }
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    console.log(err);
  }
};

// Get Branch Todays / Completed / Cancelled  Orders
export const getBranchOrders = () => async (dispatch) => {
  try {
    const res = await api.get("order/branch/status?type=today");

    dispatch({
      type: GET_BRANCHORDER,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

//add DELIERVY BOY
export const addDeliveryBoy = (data) => async (dispatch) => {
  let fd = new FormData();
  fd.append("username", data.username);
  fd.append("name", data.name);
  fd.append("mobile", data.mobile);
  fd.append("idNumber", data.idNumber);
  fd.append("DOB", data.dob);
  fd.append("bloodGroup", data.bloodGroup);
  fd.append("email", data.email);
  fd.append("place", data.place);
  fd.append("password", data.password);
  fd.append("filename", "kannur");
  fd.append("folder", "deliveryboy");
  fd.append("image", data.profile);
  fd.append("image", data.drivingLicense);

  try {
    const res = await api.post("/delivery", fd);
    if (res.data.success) {
      dispatch(setAlert("Delivery boy added", "success"));
      dispatch(getDeliveryBoy());
    } else {
      dispatch(setAlert(res.data.message, "info"));
    }
  } catch (error) {
    console.log(error);
  }
};

//get all DELIVERY BOYS
export const getDeliveryBoy = () => async (dispatch, getState) => {
  const myBranchId =
    getState().auth.branch?._id || getState().auth.executive.branch?._id;

  try {
    if (myBranchId) {
      const res = await api.get(`/delivery?branch=${myBranchId}`);
      dispatch({
        type: GET_DELIVERYBOY,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

// Delete Delivery Boy
export const deleteDeliveryboys = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/delivery/${id}`);
    dispatch(getDeliveryBoy());
    dispatch(setAlert("Delivery boy Deleted", "danger"));
  } catch (err) {
    console.log(err);
  }
};

// Get products of Restaurant
export const getProducts =
  (searchString, category) => async (dispatch, getState) => {
    // dispatch({ type: LOADING });
    const storeId = getState().branch.currentStore;

    const data = {
      page: 1,
      limit: 500,
      keyword: searchString ? searchString : "",
      vendorId: storeId,
    };

    try {
      const res = await api.post(`/product/search?type=restaurant`, data);

      dispatch({
        type: GET_PRODUCTS,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

// Add Executives (Delivery, Order, Offline)
export const addExecutive = (data, myBranchId) => async (dispatch) => {
  try {
    const res = await api.post(`/executive?type=${data.type}&branch`, data);
    if (res.data.success) {
      dispatch(setAlert(res.data.message, "success"));
      dispatch(getExecutives(myBranchId));
    }
  } catch (err) {
    if (err.response.data.error === "Duplicate field value entered") {
      dispatch(setAlert("Username already exist", "danger"));
    }
  }
};

//Get all Executives
export const getExecutives = () => async (dispatch, getState) => {
  const myBranchId = getState().auth.branch._id;
  try {
    const res = await api.get(`/executive?branch=${myBranchId}`);

    dispatch({
      type: GET_EXECUTIVES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

// Delete Restaurant Product
export const deleteProduct = (id) => async (dispatch, getState) => {
  const store = getState().branch.currentStore;
  try {
    const res = await api.delete(`/product?vendorId=${store}&productId=${id}`);
    dispatch(getProducts());
    dispatch(setAlert("Product Deleted", "danger"));
  } catch (err) {
    console.log(err);
  }
};

// Edit Product
export const editProduct =
  (productId, data, type) => async (dispatch, getState) => {
    const store = getState().branch.currentStore;
    try {
      const res = await api.put(
        `/product?restaurantId=${store}&productId=${productId}`,
        data
      );
      dispatch(getProducts());
      dispatch(setAlert("Product Updated", "success"));
    } catch (err) {
      console.log(err);
    }
  };

// Add Restaurant Product
export const addProduct = (data) => async (dispatch, getState) => {
  const storeId = getState().branch.currentStore;
  dispatch({ type: LOADING });
  let fd = new FormData();
  fd.append("filename", data.name);
  fd.append("folder", "products");
  fd.append("type", "restaurant");
  fd.append("name", data.name);
  fd.append("category", data.category);
  fd.append("categoryType", data.categoryType);
  fd.append("meal", data.meal);
  fd.append("price", data.price);
  fd.append("bestSeller", data.bestSeller);
  fd.append("imageExist", JSON.stringify(data.imageExist));
  // fd.append("packingCharge", data.packingCharge ? data.packingCharge : "");
  fd.append("offerPrice", data.offerPrice ? data.offerPrice : "");
  // fd.append("specialTag", data.specialTag ? data.specialTag : "");
  fd.append("maafosPrice", data.maafosPrice);
  fd.append("description", data.description ? data.description : "");
  fd.append("image", data.image);

  try {
    const res = await api.post(`/product?vendorId=${storeId}`, fd);
    if (res.data.success) {
      dispatch(getProducts());
      dispatch(setAlert("Product Added", "success"));
    } else {
      dispatch(setAlert("Error", "danger"));
    }
  } catch (err) {
    dispatch({ type: LOADED });
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

// Edit Restaurant Product
export const editProductData = (data) => async (dispatch, getState) => {
  const store = getState().branch.currentStore;
  dispatch({ type: LOADING });

  let fd = new FormData();

  fd.append("type", "restaurant");
  fd.append("name", data.name);
  fd.append("category", data.category);
  fd.append("categoryType", data.categoryType);
  fd.append("meal", data.meal);
  fd.append("price", data.price);

  // if (data.packingCharge) fd.append("packingCharge", data.packingCharge);
  fd.append("offerPrice", data.offerPrice ? data.offerPrice : "");
  // fd.append("specialTag", data.specialTag ? data.specialTag : "");
  fd.append("maafosPrice", data.maafosPrice);
  fd.append("description", data.description ? data.description : "");
  fd.append("bestSeller", data.bestSeller);
  if (data.image) {
    fd.append("filename", data.name);
    fd.append("folder", "products");
    fd.append("image", data.image);
  }

  try {
    const res = await api.put(
      `/product?vendorId=${store}&productId=${data.id}`,
      fd
    );
    dispatch(getProducts());
    dispatch(setAlert("Product Updated", "success"));
  } catch (err) {
    dispatch({ type: LOADED });
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

// Delete Product Image
export const deleteProductImage = (productId) => async (dispatch, getState) => {
  const store = getState().branch.currentStore;
  dispatch({ type: LOADING });

  let fd = new FormData();

  fd.append("deleteImage", true);

  try {
    const res = await api.put(
      `/product?vendorId=${store}&productId=${productId}`,
      fd
    );
    dispatch(getProducts());
    dispatch(setAlert("Product Updated", "success"));
  } catch (err) {
    dispatch({ type: LOADED });
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

//Add Clickable Banners
export const addBanner = (image, storeId) => async (dispatch) => {
  dispatch({ type: LOADING });
  let fd = new FormData();
  fd.append("filename", Date.now().toString());
  fd.append("folder", "banners");
  if (storeId) {
    fd.append("linkId", storeId);
    fd.append("clickable", true);
  } else fd.append("clickable", false);
  fd.append("image", image);
  try {
    const res = await api.post("/branch/banner", fd);
    if (res.data.success) {
      dispatch(setAlert("Banner Added", "success"));
      dispatch({ type: LOADED });
      dispatch(getMyBranch());
    } else {
      dispatch(setAlert(res.data.data, "danger"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    console.log(err);
  }
};

// delete banner
export const deleteBranchBanner = (bannerID) => async (dispatch) => {
  try {
    const res = await api.delete(`/branch/banner?bannerId=${bannerID}`);
    dispatch(getMyBranch());
    dispatch(setAlert("Banner Deleted", "danger"));
  } catch (err) {
    console.log(err);
  }
};

//add Special Messeges
export const addSpecialMessage = (data) => async (dispatch) => {
  let fd = new FormData();
  fd.append("title", data.title);
  fd.append("description", data.description);
  fd.append("filename", "in-demand");
  fd.append("folder", "icon");
  fd.append("icon", data.icon);

  try {
    const res = await api.post("/branch/message", fd);
    dispatch(getMyBranch());
    dispatch(setAlert("Special Message Added", "success"));
  } catch (error) {
    console.log(error);
  }
};

// delete Special message
export const deleteMessage = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/branch/message?id=${id}`);
    dispatch(getMyBranch());
    dispatch(setAlert("Message Deleted", "danger"));
  } catch (err) {
    console.log(err);
  }
};

// Set active message
export const activateMessage = (id, status) => async (dispatch) => {
  try {
    const res = await api.put(`/branch/message?id=${id}&visible=${status}`);
    dispatch(getMyBranch());
    dispatch(setAlert("Message Activated", "success"));
  } catch (err) {
    console.log(err);
  }
};

//add Popup
export const addPopup = (image, storeId) => async (dispatch) => {
  let fd = new FormData();
  fd.append("filename", "offer");
  fd.append("folder", "popup");
  if (storeId) {
    fd.append("linkId", storeId);
    fd.append("clickable", true);
  } else fd.append("clickable", false);
  fd.append("image", image);
  try {
    const res = await api.post("branch/popup", fd);
    dispatch(getMyBranch());
    dispatch(setAlert("Popup Banner Added", "success"));
  } catch (error) {
    console.log(error);
  }
};

// delete popup
export const deletePopup = () => async (dispatch) => {
  try {
    const res = await api.delete(`/branch/popup`);
    dispatch(getMyBranch());
    dispatch(setAlert("Popup Deleted", "success"));
  } catch (err) {
    console.log(err);
  }
};

// Send notification
export const sendNotification = (data) => async (dispatch) => {
  try {
    const res = await api.post(`/branch/notify`, data);
    if (res.data.success) {
      dispatch(setAlert(res.data.message, "success"));
      dispatch(getNotifications());
    }
  } catch (err) {
    console.log(err);
  }
};

// Add offer
export const addOffer = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const fd = new FormData();
    fd.append("title", data.title);
    fd.append("subtitle", data.subtitle);
    fd.append("primary", data.primary);
    fd.append("secondary", data.secondary);
    fd.append("type", data.type);
    fd.append("link", JSON.stringify(data.link));
    fd.append("filename", "offer1");
    fd.append("folder", "offers");
    fd.append("image", data.image);
    fd.append("image", data.icon);

    const res = await api.post(`/branch/offer`, fd);
    if (res.data.success) {
      dispatch({ type: LOADED });
      dispatch(getMyBranch());
      dispatch(setAlert("Offer Added", "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Delete Offer
export const deleteOffer = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/branch/offer?offerId=${id}`);
    dispatch(getMyBranch());
    dispatch(setAlert("Offer Deleted", "success"));
  } catch (err) {
    console.log(err);
  }
};

// Change restaurant status
export const changeStoreStatus =
  (status, id, storeType) => async (dispatch) => {
    try {
      const res = await api.put(`vendor/status?type=vendor&status=${status}`, {
        vendor: id,
      });
      if (storeType === "restaurant") dispatch(getStores());
      else if (storeType === "grocery") dispatch(getGroceryStores());
      else if (storeType === "meat") dispatch(getMeatStores());

      dispatch(setAlert("Store Status Updated", "success"));
    } catch (err) {
      console.log(err);
    }
  };

// Add Bonus Types
export const addBonusTypes = (data) => async (dispatch) => {
  try {
    const res = await api.post(`/branch/bonus`, data);
    if (res.data.success) {
      dispatch(getMyBranch());
      dispatch(setAlert(res.data.message, "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Delete Bonus Types
export const deleteBonusTypes = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/branch/bonus?id=${id}`);
    if (res.data.success) {
      dispatch(getMyBranch());
      dispatch(setAlert(res.data.message, "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// get delivery boy analysis
export const getDBAnalysis = (id, date) => async (dispatch) => {
  try {
    const res = await api.get(`/delivery/analysis?dBoyId=${id}&date=${date}`);
    if (res.data.success) {
      dispatch({ type: SET_DBANALYSIS, payload: res.data.data });
    }
  } catch (err) {
    console.log(err);
  }
};

// get delivery boy todays orders
export const getDBOrders = (id, date) => async (dispatch) => {
  try {
    const res = await api.get(
      `/order/deliveryboy?deliveryboy=${id}&date=${date}`
    );
    if (res.data.success) {
      dispatch({
        type: GET_BRANCHORDER,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Delivery boy settlement
export const settleDelivery = (data) => async (dispatch) => {
  try {
    const res = await api.post(`/settlement?type=delivery`, data);
    dispatch(setAlert(res.data.message, "success"));
    return true;
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

// get promocodes
export const getPromocodes = () => async (dispatch) => {
  try {
    const res = await api.get(`/promocode`);
    if (res.data.success) {
      dispatch({
        type: GET_PROMOCODES,
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Add promocodes
export const addPromocode = (data) => async (dispatch) => {
  try {
    const res = await api.post(`/promocode`, data);
    if (res.data.success) {
      dispatch(getPromocodes());
      dispatch(setAlert("Promocode Added", "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Delete promocodes
export const deletePromo = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/promocode?id=${id}`);
    if (res.data.success) {
      dispatch(getPromocodes());
      dispatch(setAlert("Promocode Deleted", "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Edit promocodes
export const editPromo = (id, status) => async (dispatch) => {
  try {
    const res = await api.put(`/promocode?id=${id}`, { status });
    if (res.data.success) {
      dispatch(getPromocodes());
      dispatch(setAlert("Promocode status updated", "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Add category to store
export const addStoreCategory = (data) => async (dispatch, getState) => {
  const store = getState().branch.currentStore;
  try {
    const res = await api.post(`vendor/category?id=${store}`, data);
    if (res.data.success) {
      dispatch(setAlert(`Category Added to ${res.data.data.name}`, "success"));
      dispatch(getStores());
    }
  } catch (err) {
    console.log(err);
  }
};

// Delete category from store
export const deleteStoreCategory = (catId) => async (dispatch, getState) => {
  const store = getState().branch.currentStore;
  try {
    const res = await api.delete(
      `vendor/category?id=${store}&categoryId=${catId}`
    );
    if (res.data.success) {
      dispatch(setAlert(`Category Deleted`, "success"));
      dispatch(getStores());
    }
  } catch (err) {
    console.log(err);
  }
};

// Change category status from store
export const StoreCategoryStatus =
  (catId, data) => async (dispatch, getState) => {
    try {
      const res = await api.put(`vendor/category?id=${catId}`, data);
      if (res.data.success) {
        dispatch(setAlert(`Category Status Updated`, "success"));
        dispatch(getStores());
      }
    } catch (err) {
      console.log(err);
    }
  };

//Add grocery category
export const addGroceryCategory =
  (categoryId) => async (dispatch, getState) => {
    try {
      const store = getState().branch.currentGroceryStore;
      const res = await api.post(
        `/vendor/category-grocery?vendorId=${store}`,
        categoryId
      );
      if (res.data.success) {
        dispatch(setAlert("Category Added", "success"));
        dispatch(getGroceryStores());
      }
    } catch (err) {
      console.log(err.response);
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  };

//Update grocery store category status
export const groceryCategoryStatus =
  (catId, data) => async (dispatch, getState) => {
    try {
      const res = await api.put(`vendor/category?id=${catId}`, data);
      if (res.data.success) {
        dispatch(setAlert(`Category Status Updated`, "success"));
        dispatch(getGroceryStores());
      }
    } catch (err) {
      console.log(err);
    }
  };

// Delete category from grocery store
export const deleteGroceyCategory = (catId) => async (dispatch, getState) => {
  const store = getState().branch.currentGroceryStore;
  try {
    const res = await api.delete(
      `/vendor/category-grocery?vendorId=${store}&categoryId=${catId}`
    );
    if (res.data.success) {
      dispatch(setAlert(`Category Deleted`, "success"));
      dispatch(getGroceryStores());
    }
  } catch (err) {
    console.log(err);
  }
};

// Add Grocery Product
export const addGroceryProduct = (data) => async (dispatch, getState) => {
  const storeId = getState().branch.currentGroceryStore;
  dispatch({ type: LOADING });
  let fd = new FormData();
  fd.append("type", "grocery");
  fd.append("name", data.name);
  fd.append("category", data.category);
  fd.append("price", data.price);
  fd.append("imageExist", JSON.stringify(data.imageExist));
  fd.append("bestSeller", data.bestSeller);
  // fd.append("packingCharge", data.packingCharge ? data.packingCharge : "");
  fd.append("offerPrice", data.offerPrice ? data.offerPrice : "");
  // fd.append("specialTag", data.specialTag ? data.specialTag : "");
  fd.append("maafosPrice", data.maafosPrice);
  fd.append("description", data.description ? data.description : "");
  fd.append("filename", data.name);
  fd.append("folder", "products");
  fd.append("image", data.image);

  try {
    const res = await api.post(`/product?vendorId=${storeId}`, fd);
    if (res.data.success) {
      dispatch(setAlert("Product Added", "success"));
      dispatch(
        getGroceryProducts(
          getState().branch.groceryAcitvePage,
          getState().branch.groceryKeyword
        )
      );
    } else {
      dispatch(setAlert("Error", "danger"));
    }
    dispatch({ type: LOADED });
  } catch (err) {
    dispatch({ type: LOADED });
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

// Get products of Grocery
export const getGroceryProducts =
  (activePage, keyword) => async (dispatch, getState) => {
    const storeId = getState().branch.currentGroceryStore;

    const data = {
      page: activePage,
      limit: getState().branch.groceryOnePageLimit,
      keyword: keyword,
      vendorId: storeId,
    };

    try {
      const res = await api.post(`/product/search?type=grocery`, data);
      const totalPages = Math.ceil(
        res.data.productSearchCount / getState().branch.groceryOnePageLimit
      );

      dispatch({
        type: GET_GROCERY_PRODUCTS,
        payload: {
          data: res.data.data,
          totalProductsCount: res.data.productsCount,
          pages: totalPages,
          activePage: activePage,
          keyword: keyword,
        },
      });
    } catch (err) {
      console.log(err.response);
    }
  };

// Edit Grocery Product
export const editGroceryProductData = (data) => async (dispatch, getState) => {
  const store = getState().branch.currentGroceryStore;
  dispatch({ type: LOADING });

  let fd = new FormData();

  fd.append("type", "grocery");
  fd.append("name", data.name);
  fd.append("category", data.category);
  fd.append("price", data.price);
  fd.append("imageExist", JSON.stringify(data.imageExist));
  // fd.append("packingCharge", data.packingCharge ? data.packingCharge : "");
  fd.append("offerPrice", data.offerPrice ? data.offerPrice : "");
  // fd.append("specialTag", data.specialTag ? data.specialTag : "");
  fd.append("maafosPrice", data.maafosPrice);
  fd.append("description", data.description ? data.description : "");
  fd.append("bestSeller", data.bestSeller);
  if (data.image) {
    fd.append("filename", data.name);
    fd.append("folder", "products");
    fd.append("image", data.image);
  }

  try {
    const res = await api.put(
      `/product?vendorId=${store}&productId=${data.id}`,
      fd
    );
    dispatch(setAlert("Product Updated", "success"));
    dispatch(
      getGroceryProducts(
        getState().branch.groceryAcitvePage,
        getState().branch.groceryKeyword
      )
    );
    dispatch({ type: LOADED });
  } catch (err) {
    dispatch({ type: LOADED });
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

// Delete Grocery Product
export const deleteGroceryProduct = (id) => async (dispatch, getState) => {
  const store = getState().branch.currentGroceryStore;
  try {
    const res = await api.delete(`/product?vendorId=${store}&productId=${id}`);
    dispatch(
      getGroceryProducts(
        getState().branch.groceryAcitvePage,
        getState().branch.groceryKeyword
      )
    );
    dispatch(setAlert("Product Deleted", "danger"));
  } catch (err) {
    console.log(err);
  }
};

//Add meat category to store
export const addMeatCategory = (categoryId) => async (dispatch, getState) => {
  try {
    const store = getState().branch.currentMeatStore;
    const res = await api.post(
      `/vendor/category-grocery?vendorId=${store}`,
      categoryId
    );
    if (res.data.success) {
      dispatch(setAlert("Category Added", "success"));
      dispatch(getMeatStores());
    }
  } catch (err) {
    console.log(err.response);
    dispatch(setAlert(err.response.data.message, "danger"));
  }
};

// Delete category from meat store
export const deleteMeatCategory = (catId) => async (dispatch, getState) => {
  const store = getState().branch.currentMeatStore;
  try {
    const res = await api.delete(
      `/vendor/category-grocery?vendorId=${store}&categoryId=${catId}`
    );
    if (res.data.success) {
      dispatch(setAlert(`Category Deleted`, "success"));
      dispatch(getMeatStores());
    }
  } catch (err) {
    console.log(err);
  }
};

//Update meat store category status
export const meatCategoryStatus =
  (catId, data) => async (dispatch, getState) => {
    try {
      const res = await api.put(`vendor/category?id=${catId}`, data);
      if (res.data.success) {
        dispatch(setAlert(`Category Status Updated`, "success"));
        dispatch(getMeatStores());
      }
    } catch (err) {
      console.log(err);
    }
  };

// Add Meat Product
export const addMeatProduct = (data) => async (dispatch, getState) => {
  const storeId = getState().branch.currentMeatStore;
  // dispatch({ type: LOADING });
  let fd = new FormData();
  fd.append("type", "meat");
  fd.append("name", data.name);
  fd.append("category", data.category);
  fd.append("price", data.price);
  fd.append("imageExist", JSON.stringify(data.imageExist));
  // fd.append("packingCharge", data.packingCharge ? data.packingCharge : "");
  fd.append("offerPrice", data.offerPrice ? data.offerPrice : "");
  // fd.append("specialTag", data.specialTag ? data.specialTag : "");
  fd.append("maafosPrice", data.maafosPrice);
  fd.append("description", data.description ? data.description : "");
  fd.append("bestSeller", data.bestSeller);
  fd.append("filename", data.name);
  fd.append("folder", "products");
  fd.append("image", data.image);

  try {
    const res = await api.post(`/product?vendorId=${storeId}`, fd);
    if (res.data.success) {
      dispatch(setAlert("Product Added", "success"));
      dispatch(getMeatProducts());
    } else {
      dispatch(setAlert("Error", "danger"));
    }
    dispatch({ type: LOADED });
  } catch (err) {
    dispatch({ type: LOADED });
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

// Get products of Meat
export const getMeatProducts = (searchString) => async (dispatch, getState) => {
  const storeId = getState().branch.currentMeatStore;

  const data = {
    page: 1,
    limit: 500,
    keyword: searchString ? searchString : "",
    vendorId: storeId,
  };
  try {
    const res = await api.post(`/product/search?type=meat`, data);
    dispatch({
      type: GET_MEAT_PRODUCTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err.response);
  }
};

export const editMeatProductData = (data) => async (dispatch, getState) => {
  const storeId = getState().branch.currentMeatStore;
  dispatch({ type: LOADING });

  let fd = new FormData();

  fd.append("type", "meat");
  fd.append("name", data.name);
  fd.append("category", data.category);
  fd.append("price", data.price);
  fd.append("imageExist", JSON.stringify(data.imageExist));
  // fd.append("packingCharge", data.packingCharge ? data.packingCharge : "");
  fd.append("offerPrice", data.offerPrice ? data.offerPrice : "");
  // fd.append("specialTag", data.specialTag ? data.specialTag : "");
  fd.append("maafosPrice", data.maafosPrice);
  fd.append("description", data.description ? data.description : "");
  fd.append("bestSeller", data.bestSeller);
  if (data.image) {
    fd.append("filename", data.name);
    fd.append("folder", "products");
    fd.append("image", data.image);
  }

  try {
    const res = await api.put(
      `/product?vendorId=${storeId}&productId=${data.id}`,
      fd
    );
    dispatch(setAlert("Product Updated", "success"));
    dispatch(getMeatProducts());
    dispatch({ type: LOADED });
  } catch (err) {
    dispatch({ type: LOADED });
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

// Delete Grocery Product
export const deleteMeatProduct = (id) => async (dispatch, getState) => {
  const storeId = getState().branch.currentMeatStore;
  try {
    const res = await api.delete(
      `/product?vendorId=${storeId}&productId=${id}`
    );
    dispatch(dispatch(getMeatProducts()));
    dispatch(setAlert("Product Deleted", "danger"));
  } catch (err) {
    console.log(err);
  }
};

// Update Product Status
export const updateProductStatus =
  (vendorId, productId, status, storeType) => async (dispatch, getState) => {
    try {
      const res = await api.put(
        `/product?vendorId=${vendorId}&productId=${productId}`,
        { status: status }
      );
      dispatch(setAlert("Status updated", "success"));
      if (storeType === "restaurant") {
        dispatch(getProducts());
      } else if (storeType === "grocery") {
        let activePage = getState().branch.groceryAcitvePage;
        let keyword = getState().branch.groceryKeyword;
        dispatch(getGroceryProducts(activePage, keyword));
      } else if (storeType === "meat") {
        dispatch(getMeatProducts());
      }
    } catch (err) {
      console.log(err.response);
    }
  };

// Get all type vendors
export const getAllVendors = () => async (dispatch) => {
  try {
    const res = await api.get(`/vendor/all`);
    dispatch({
      type: GET_ALL_STORES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err.response);
  }
};

//Block customer
export const blockCustomer = (data) => async (dispatch) => {
  try {
    const res = await api.post(`/customer/block?customerId=${data._id}`, {
      block: true,
      blockedReason: data.blockedReason,
    });
    if (res.data.success) {
      dispatch(setAlert("Customer Blocked", "success"));
    }
    return true;
  } catch (err) {
    console.log(err.response);
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

//Unblock customer
export const unblockCustomer = (data) => async (dispatch) => {
  try {
    let fd = {
      dueAmount: data.dueAmount,
      type: "cash",
    };
    const res = await api.put(
      `/customer/block?customerId=${data.customerId}`,
      fd
    );
    if (res.data.success) {
      dispatch(setAlert("Customer Unblocked", "success"));
    }
    return true;
  } catch (err) {
    console.log(err.response);
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

// Product whole addon status
export const updateProductAddonStatus = (data) => async (dispatch) => {
  try {
    const res = await api.put(
      `/product/addon-status-all?productId=${data.productId}&status=${data.status}`
    );

    if (res.data.success) {
      dispatch(setAlert("Addon Status updated", "success"));
      dispatch(getProducts());
    }
    return true;
  } catch (err) {
    console.log(err.response);
    dispatch(setAlert("Error occured", "danger"));
  }
};

// Update product timing
export const updateProuductTiming = (data) => async (dispatch) => {
  try {
    const res = await api.put(
      `/product/timing?productId=${data.productId}`,
      data
    );
    if (res.data.success) {
      dispatch(setAlert("Product timing updated", "success"));
      dispatch(getProducts());
    }
    return true;
  } catch (err) {
    console.log(err.response);
    dispatch(setAlert("Error occured", "danger"));
  }
};

// Update product timing status
export const updateProuductTimingStatus = (data) => async (dispatch) => {
  try {
    const res = await api.put(
      `/product/timing-status?productId=${data.productId}&status=${data.status}`
    );
    if (res.data.success) {
      dispatch(setAlert("Product timing status updated", "success"));
      dispatch(getProducts());
    }
    return true;
  } catch (err) {
    console.log(err.response);
    dispatch(setAlert("Error occured", "danger"));
  }
};

export const getNotifications = () => async (dispatch) => {
  try {
    const res = await api.get(`/branch/notify`);
    dispatch({
      type: GET_NOTIFICATION,
      payload: res.data,
    });
  } catch (err) {
    console.log(err.response);
  }
};

export const deleteNotification = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/branch/notify?id=${id}`);
    if (res.data.success) {
      dispatch(setAlert("Notification deleted", "danger"));
      dispatch(getNotifications());
    }
  } catch (err) {
    console.log(err.response);
  }
};

// Product whole variant status
export const updateProductVariantStatus = (data) => async (dispatch) => {
  try {
    const res = await api.put(
      `/product/variant-status-all?productId=${data.productId}&status=${data.status}`
    );

    if (res.data.success) {
      dispatch(setAlert("Variant Status updated", "success"));
      dispatch(getProducts());
    }
    return true;
  } catch (err) {
    console.log(err.response);
    dispatch(setAlert("Error occured", "danger"));
  }
};
