// import { number } from "prop-types";
import {
  LOADED,
  LOADING,
  GET_CATEGORY,
  APP_STATUS,
  CHANGE_DHELIMIT,
  CHANGE_OHELIMIT,
  GET_CATEGORY_TYPES,
  GET_GROCERY_CATEGORY,
  GET_MEAT_CATEGORY,
} from "./constants";

const initialState = {
  loading: false,
  categories: [],
  categoryTypes: [],
  groceryCategories: [],
  meatCategories: [],
  appStatus: false,
  DHELimit: 0,
  OHELimit: 0,
};

export default function settings(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };

    case LOADED:
      return {
        ...state,
        loading: false,
      };

    case GET_CATEGORY:
      return {
        ...state,
        categories: payload,
      };

    case APP_STATUS:
      return {
        ...state,
        appStatus: payload.appStatus,
        DHELimit: payload.DHELimit,
        OHELimit: payload.OHELimit,
      };

    case CHANGE_OHELIMIT:
      return {
        ...state,
        OHELimit: payload,
      };

    case CHANGE_DHELIMIT:
      return {
        ...state,
        DHELimit: payload,
      };

    case GET_CATEGORY_TYPES:
      return {
        ...state,
        categoryTypes: payload.data,
      };

    case GET_GROCERY_CATEGORY:
      return {
        ...state,
        groceryCategories: payload.data,
      };

    case GET_MEAT_CATEGORY:
      return {
        ...state,
        meatCategories: payload.data,
      };

    default:
      return state;
  }
}
