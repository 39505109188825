import { CDropdownDivider } from "@coreui/react";
import React from "react";

const Divider = ({ width }) => {
  return (
    <div>
      <CDropdownDivider
        style={{
          width: width,
          height: "5px",
          backgroundColor: "#2ac72a",
          borderRadius: "100px",
        }}
      />
    </div>
  );
};

export default Divider;
